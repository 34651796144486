import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { Component, inject, Input, OnInit } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { TeamUserListModel } from 'app/models/CompanyUser';
import { UserSettings } from 'app/models/user-settings';
import { getGSettingsGlobalSettingsModel, State } from 'app/redux/reducers';

@Component({
  selector: 'user-summary',
  standalone: true,
  imports: [CurrencyPipe, MatTooltip, AsyncPipe],
  templateUrl: './user-summary.component.html'
})
export class UserSummaryComponent implements OnInit {

  @Input() user: TeamUserListModel ;
  
  globalSettingsModel$: Observable<UserSettings>;
  
  private store = inject(Store<State>);

  ngOnInit(): void {
    this.globalSettingsModel$ = this.store.select(getGSettingsGlobalSettingsModel);
  }
}
