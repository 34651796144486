import { Component, DestroyRef, inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TeamUserListModel } from 'app/models/CompanyUser';
import { AddedField, CustomFieldEntity, CustomFieldEntryForm, CustomFieldEntryModel, CustomFieldSchemaModel, DeletedField, FieldChanges, UpdatedField } from 'app/models/custom-fields';
import { CustomFieldActions } from 'app/redux/actions/customfields';
import { getCustomFieldsEntries, getCustomFieldsSchemas, State } from 'app/redux/reducers';
import { SuccessStatus, UserRole } from 'app/shared/enums';
import { combineLatest, concatMap, debounceTime, from, Observable, of } from 'rxjs';

@Component({
  selector: 'app-user-extra-data',
  templateUrl: './user-extra-data.component.html',
  styleUrls: ['./user-extra-data.component.scss']
})
export class UserExtraDataComponent implements OnInit, OnChanges {
  @Input() loading: SuccessStatus;
  @Input() user: TeamUserListModel;
  actionType: 'update' = null;
  UserRole = UserRole;
  SuccessStatus = SuccessStatus;
  
  customUserFieldsSchemas$: Observable<CustomFieldSchemaModel[]>;
  customUserFieldsSchemas: CustomFieldSchemaModel[] = [];
  customUserFieldsEntries$: Observable<CustomFieldEntryModel[]>;
  customUserFieldsEntries: CustomFieldEntryModel[] = [];
  customUserFields: FormArray<FormGroup<CustomFieldEntryForm>> = new FormArray<FormGroup<CustomFieldEntryForm>>([]);

  private destoryRef = inject(DestroyRef);
  private store = inject(Store<State>);

  get saveStatus(): SuccessStatus {
    if ((this.customUserFields.invalid || !this.customUserFields.dirty)) {
      return SuccessStatus.Disabled;
    } else {
      return this.actionType === 'update' ? this.loading : SuccessStatus.Enabled;
    }
  }

  ngOnInit() {
    this.customUserFieldsSchemas$ = this.store.select(getCustomFieldsSchemas);
    this.customUserFieldsEntries$ = this.store.select(getCustomFieldsEntries);
    combineLatest([this.customUserFieldsSchemas$, this.customUserFieldsEntries$])
      .pipe(
        debounceTime(100),
        takeUntilDestroyed(this.destoryRef),
      ).subscribe(([schemas, entries]) => {
        this.customUserFieldsSchemas = schemas.filter(schema => schema.customFieldEntity === CustomFieldEntity.User);
        this.customUserFieldsEntries = entries.filter(entry => entry.entityId === this.user.userId);
        this.customUserFields = this._initCustomFields(this.customUserFieldsSchemas, this.customUserFieldsEntries);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading) {
      if (changes.loading.currentValue !== SuccessStatus.IsLoading) {
        this.actionType = null;
      }
    }
  }

  public saveExtraData(): void {
    this.actionType = 'update';
    const {addedFields, updatedFields, deletedFields} = this._getAddedUpdatedDeletedFields();

    const addedSubscription = from(addedFields).pipe(
      concatMap(({ entryToAdd, originalEntry }) => {
        return of(this.store.dispatch(CustomFieldActions.createCustomFieldEntry({ entryToAdd, originalEntry })));
      })
    ).subscribe(() => addedSubscription.unsubscribe());

    const updatedSubscription = from(updatedFields).pipe(
      concatMap(({ entryToUpdate, originalEntry }) => {
        return of(this.store.dispatch(CustomFieldActions.updateCustomFieldEntry({ entryToUpdate, originalEntry })));
      })
    ).subscribe(() => updatedSubscription.unsubscribe());

    const deletedSubscription = from(deletedFields).pipe(
      concatMap(({ entryToDelete, originalEntry, defaultFieldValue }) => {
        return of(this.store.dispatch(CustomFieldActions.deleteCustomFieldEntry({ entryToDelete, originalEntry, defaultFieldValue })));
      })
    ).subscribe(() => deletedSubscription.unsubscribe());
  }

  private _buildAddedField(
    fieldName: string,
    fieldValue: string,
    customFieldEntity: CustomFieldEntity,
    entityId: string,
    originalEntry: CustomFieldEntryModel
  ): AddedField {
    const entryToAdd = { fieldName, fieldValue, customFieldEntity, entityId };

    return { entryToAdd, originalEntry };
  }

  private _buildDeletedField(
    fieldName: string,
    customFieldEntity: CustomFieldEntity,
    entityId: string,
    originalEntry: CustomFieldEntryModel,
    schema: CustomFieldSchemaModel,
  ): DeletedField {
    const entryToDelete = { fieldName, customFieldEntity, entityId };

    return { entryToDelete, originalEntry, defaultFieldValue: schema.defaultFieldValue  };
  }

  private _buildUpdatedField(
    fieldName: string,
    fieldValue: string,
    customFieldEntity: CustomFieldEntity,
    entityId: string,
    originalEntry: CustomFieldEntryModel
  ): UpdatedField {
    const entryToUpdate = { fieldName, fieldValue, customFieldEntity, entityId };

    return { entryToUpdate, originalEntry };
  }

  private _getAddedUpdatedDeletedFields(): FieldChanges {
    const previousEntriesMap = new Map(this.customUserFieldsEntries.map(entry => [entry.fieldName, entry]));
    const schemaMap = new Map(this.customUserFieldsSchemas.map(schema => [schema.fieldName, schema]));

    const result: FieldChanges = {
      addedFields: [],
      updatedFields: [],
      deletedFields: [],
    };

    this.customUserFields.controls.forEach(formGroup => {
      Object.entries(formGroup.controls).forEach(([fieldName, current]) => {
        this._processField(fieldName, current.value, previousEntriesMap, schemaMap, result);
      });
    });

    return result;
  }

  private _initCustomFields(schemas: CustomFieldSchemaModel[], entries: CustomFieldEntryModel[]): FormArray<FormGroup<CustomFieldEntryForm>> {
    const customFields = schemas.map(schema => {
      const entry = entries.find(entry => entry.fieldName === schema.fieldName);
  
      return new FormGroup<CustomFieldEntryForm>({
        [schema.fieldName]: new FormControl<string | null>(entry ? entry.fieldValue : schema.defaultFieldValue || null)
      });
    });
  
    return new FormArray<FormGroup<CustomFieldEntryForm>>(customFields);
  }

  private _isFieldChanged(
    previous: CustomFieldEntryModel | undefined,
    schema: CustomFieldSchemaModel | undefined,
    currentValue: string
  ): boolean {
    return previous
      ? previous.fieldValue !== currentValue
      : schema?.defaultFieldValue !== currentValue;
  }

  private _processField(
    fieldName: string,
    currentValue: string,
    previousEntriesMap: Map<string, CustomFieldEntryModel>,
    schemaMap: Map<string, CustomFieldSchemaModel>,
    result: FieldChanges
  ): void {
    const previous = previousEntriesMap.get(fieldName);
    const schema = schemaMap.get(fieldName);
    const customFieldEntity = CustomFieldEntity.User;
    const entityId = this.user.userId;
  
    if (!this._isFieldChanged(previous, schema, currentValue)) return;
  
    if (previous && schema) {
      const wasDefault = previous.isDefaultValue;
      const isNowDefault = currentValue !== '' ? schema.defaultFieldValue === currentValue : schema.defaultFieldValue === null;

      if (wasDefault && !isNowDefault) {
        const addedField = this._buildAddedField(fieldName, currentValue, customFieldEntity, entityId, previous);
        result.addedFields.push(addedField);
      } else if (!wasDefault && !isNowDefault) {
        const updatedField = this._buildUpdatedField(fieldName, currentValue, customFieldEntity, entityId, previous);
        result.updatedFields.push(updatedField);
      } else if (!wasDefault && isNowDefault) {
        const deletedField = this._buildDeletedField(fieldName, customFieldEntity, entityId, previous, schema);
        result.deletedFields.push(deletedField);
      }
    } else {
      const addedField = this._buildAddedField(fieldName, currentValue, customFieldEntity, entityId, previous);
      result.addedFields.push(addedField);
    }
  }
}
