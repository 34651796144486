import { Component, DestroyRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CompanyUserPagedRequest, TeamUserListModel } from 'app/models/CompanyUser';
import { CustomFieldEntity, CustomFieldEntryModel, CustomFieldSchemaModel } from 'app/models/custom-fields';
import { UserSettings } from 'app/models/user-settings';
import { CustomFieldActions } from 'app/redux/actions/customfields';
import { State, getCompanyLoading, getCustomFieldsEntriesLoading, getCustomFieldsSchemas } from 'app/redux/reducers';
import { PATTERNS } from 'app/shared/consts';
import { SuccessStatus } from 'app/shared/enums';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss']
})
export class UserInformationComponent implements OnInit, OnChanges {
  @Input() public user: TeamUserListModel;
  @Input() public isMobile: boolean;
  @Input() public filterOptions: CompanyUserPagedRequest = new CompanyUserPagedRequest();
  @Output() public closeDrawer: EventEmitter<void> = new EventEmitter<void>();

  public SuccessStatus = SuccessStatus;
  public loading$: Observable<SuccessStatus>;
  public loadingExtraData$: Observable<SuccessStatus>;
  public getGlobalSettingsModel$: Observable<UserSettings>;
  public getCustomUserFieldsSchemas: CustomFieldSchemaModel[] = [];
  public getCustomUserFieldsEntries: CustomFieldEntryModel[] = [];
  public selectedTabIndex: number = 0;
  public editUserForm: UntypedFormGroup = new UntypedFormGroup({
    userName: new FormControl(null, [Validators.required]),
    email: new FormControl(null, [Validators.required, Validators.pattern(PATTERNS.Email)]),
    clients: new FormControl({value: [], disabled: true}),
    companyRole: new UntypedFormGroup({
      hourlyRate: new FormControl(null, [Validators.required, Validators.pattern(PATTERNS.DecimalWithMax9999)]),
      otHourlyRate: new FormControl(null, [Validators.pattern(PATTERNS.DecimalWithMax9999)]),
      userRole: new FormControl(null, [Validators.required]),
    })
  });

  constructor(
    private store: Store<State>,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.loading$ = this.store.select(getCompanyLoading);
    this.loadingExtraData$ = this.store.select(getCustomFieldsEntriesLoading);
    this.store.dispatch(CustomFieldActions.getCustomFieldSchemasByEntity({ entity: CustomFieldEntity.User }));
    this.store.select(getCustomFieldsSchemas)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(schemas => {
        this.getCustomUserFieldsSchemas = schemas.filter(schema => schema.customFieldEntity === CustomFieldEntity.User);
      });
    this.store.dispatch(CustomFieldActions.getCustomFieldEntriesByEntityAndEntityIds({ entity: CustomFieldEntity.User, entityIds: this.user?.userId ? [this.user.userId] : [] }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user?.currentValue) {
      this.selectedTabIndex = 0;
      this.editUserForm.patchValue({
        ...this.user,
        clients: this.user.clients.map(s => s.clientName)
      });
      this.editUserForm.markAsPristine();
    }
  }
}
