import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CartwheelSelectOptions } from '@cartwheel/web-components';
import { Store } from '@ngrx/store';
import { CompanyUser, CompanyUserPagedRequest, TeamUserListModel } from 'app/models/CompanyUser';
import { DeleteCompanyUsersAction, UpdateUserInCompanyAction } from 'app/redux/actions/user';
import { State } from 'app/redux/reducers';
import { ConfirmDialogComponent } from 'app/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { SuccessStatus, UserRole } from 'app/shared/enums';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnChanges {
  @Input() user: TeamUserListModel;
  @Input() formGroup: UntypedFormGroup;
  @Input() loading: SuccessStatus;
  @Input() filterOptions: CompanyUserPagedRequest = new CompanyUserPagedRequest();
  UserRole = UserRole;
  SuccessStatus = SuccessStatus;
  actionType: 'update' | 'delete' = null;
  usrRoles: CartwheelSelectOptions<UserRole> = [
    {label: 'Admin', value: UserRole.Admin},
    {label: 'Manager', value: UserRole.Manager},
    {label: 'User', value: UserRole.User}
  ];

  constructor(
    private dialogService: MatDialog,
    private store: Store<State>,
  ) {}

  get saveStatus(): SuccessStatus {
    if ((this.formGroup.invalid || !this.formGroup.dirty)) {
      return SuccessStatus.Disabled;
    } else {
      return this.actionType === 'update' ? this.loading : SuccessStatus.Enabled;
    }
  }

  get deleteStatus(): SuccessStatus {
    return this.actionType === 'delete' ? this.loading : SuccessStatus.Enabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading) {
      if (changes.loading.currentValue !== SuccessStatus.IsLoading) {
        this.actionType = null;
      }
    }
  }

  deleteUser(): void {
    this.dialogService.open(ConfirmDialogComponent, {
      data: {
        confirmationAction: () => {
          this.actionType = 'delete';
          this.store.dispatch(new DeleteCompanyUsersAction({
            users: [
              new CompanyUser({
                ...this.user,
                ...this.formGroup.getRawValue(),
                companyRole: {
                  ...this.user.companyRole,
                  ...this.formGroup.get('companyRole').getRawValue()
                }
              })
            ],
            filterOptions: this.filterOptions
          }));
        },
        message: 'Do you want to delete the user ?'
      }
    });
  }

  submit(): void {
    this.actionType = 'update';
    this.store.dispatch(new UpdateUserInCompanyAction({
      user: new CompanyUser({
        ...this.user,
        ...this.formGroup.getRawValue(),
        companyRole: {
          ...this.user.companyRole,
          ...this.formGroup.get('companyRole').getRawValue()
        }
      }),
      filterOptions: this.filterOptions
    }));
  }
}
