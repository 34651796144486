@if (formGroup && user) {
  <form class="px-5 pb-5 pt-30" [formGroup]="formGroup" (ngSubmit)="submit()">
    <div class="grid grid-cols-2 gap-x-[10%] gap-y-30">
      <cartwheel-input class="block" label="User name" type="text" [inputFormControl]="formGroup.get('userName') | formControlType" />
      <cartwheel-input class="block" label="Email" type="text" [inputFormControl]="formGroup.get('email') | formControlType" />
      <ng-container [formGroup]="formGroup.get('companyRole') | formGroupType">
        <cartwheel-input class="block" label="Hourly Rate" type="number" [inputFormControl]="formGroup.get('companyRole.hourlyRate') | formControlType" />
        <cartwheel-input class="block" label="O/T Rate" type="number" [inputFormControl]="formGroup.get('companyRole.otHourlyRate') | formControlType" />
      </ng-container>
      <cartwheel-select class="block col-span-2" label="User Role" [selectFormControl]="formGroup.get('companyRole.userRole') | formControlType" [options]="usrRoles" />
      <cartwheel-chip-grid class="block col-span-2" label="Clients" [chipFormControl]="formGroup.get('clients') | formControlType" />
    </div>

    <div class="grid grid-cols-2 gap-x-[10%] mt-30">
      <cartwheel-button
        statusButton
        submitButton
        label="Save"
        type="raised-button"
        color="secondary"
        [status]="saveStatus"
      />
      <cartwheel-button
        statusButton
        label="Delete"
        color="danger"
        [status]="deleteStatus"
        (onClick)="deleteUser()"
      />
    </div>
  </form>
}