import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyUserPagedRequest, TeamUserListModel } from 'app/models/CompanyUser';

@Component({
  selector: 'app-user-information-dialog',
  templateUrl: './user-information-dialog.component.html',
  styleUrls: ['./user-information-dialog.component.scss']
})
export class UserInformationDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {user: TeamUserListModel, filterOptions: CompanyUserPagedRequest},
    public dialogRef: MatDialogRef<UserInformationDialogComponent>
  ) { }
}
