<div class="card" [class.isMobile]="isMobile">
  <div class="content">
    <div class="header-container" id="header">
      <div class="heading">
        <div class="header-name">{{ user?.firstName }} {{ user?.lastName }}</div>
        <cartwheel-icon-button
          matIconName="clear"
          color="darkGray"
          tooltip="Close user details"
          type="icon-button"
          (onClick)="closeDrawer.emit()"
        />
      </div>
    </div>
    <div>
      <user-summary [user]="user" />
      <mat-tab-group
        disableRipple
        class="card-tabs mt-15"
        [(selectedIndex)]="selectedTabIndex">
        <mat-tab label="Details" [disabled]="(loading$ | async) === SuccessStatus.IsLoading" />
        @if (getCustomUserFieldsSchemas.length > 0) {
          <mat-tab label="Extra Data" [disabled]="(loading$ | async) === SuccessStatus.IsLoading"/>
        }
        <!-- <mat-tab label="History" [disabled]="(loading$ | async) === SuccessStatus.IsLoading"></mat-tab> -->
      </mat-tab-group>

      @switch (selectedTabIndex) {
        @case (0) {
          <app-user-details
            [formGroup]="editUserForm"
            [filterOptions]="filterOptions"
            [user]="user"
            [loading]="loading$ | async"
          />
        }
        @case (1) {
          <app-user-extra-data
            [formGroup]="editUserForm"
            [loading]="loadingExtraData$ | async"
            [user]="user"
          />
        }
      }
    </div>
  </div>
</div>
