<div class="px-5 pb-5 pt-30">
  <div class="grid grid-cols-1 gap-x-[10%] gap-y-30">
    @for (customUserFieldForm of customUserFields.controls; track 'form-group' + $index) {
      <div [formGroup]="customUserFieldForm">
        @for (control of customUserFieldForm.controls | keyvalue; track 'form-control' + $index) {
          <cartwheel-input 
            class="block"
            type="text"
            [label]="control.key"
            [inputFormControl]="control.value | formControlType"
          />
        }
      </div>
    }
  </div>
  <div class="grid grid-cols-2 gap-x-[10%] mt-30">
    <cartwheel-button
      statusButton
      submitButton
      label="Save"
      type="raised-button"
      color="secondary"
      [status]="saveStatus"
      (onClick)="saveExtraData()"
    />
  </div>
</div>